import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { LanguageModule } from './language/language.module';
import { APP_ENV } from './constants';
import { LoggerService } from './logging';
import { WithCredentialsInterceptor } from './interceptors';
import { IEnvironment } from './interfaces/environment.interface';

@NgModule({
  declarations: [],
  imports: [RouterModule, HttpClientModule, LanguageModule],
  exports: [TranslateModule],
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule,
    private translateSvc: TranslateService
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
    this.translateSvc.use('en');
  }

  static forRoot(environment: IEnvironment): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        LoggerService,
        {
          provide: APP_ENV, // use InjectionToken
          useValue: environment,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: WithCredentialsInterceptor,
          multi: true,
        },
      ],
    };
  }
}

const throwIfAlreadyLoaded = (
  parentModule: unknown,
  moduleName: string
): void => {
  if (parentModule) {
    throw new Error(
      `${moduleName} has already been loaded. Import Core modules in the AppModule only.`
    );
  }
};
