export class BaseFieldPropertyDefaults {
  static readonly MaxShortCodeLength = 3;
  static readonly MaxPostalCodeLength = 10;
  static readonly MaxTaxCodeLength = 25;
  static readonly VatIdCodeLength = 25;
  static readonly MaxCodeLength = 50;
  static readonly MaxNameLength = 100;
  static readonly MaxEmailLength = 100;
  static readonly MaxAddressLength = 250;
  static readonly MaxDescriptionLength = 500;
  static readonly MaxWebSiteLength = 250;
  static readonly MaxPasswordLength = 200;
  static readonly MaxTranslateableInvariantLanguageValueLength = 512;
  static readonly MaxTranslatedStringLength = 512;
  static readonly MaxUriLength = 1024;
  static readonly MinUriLength = 12;
  static readonly MaxPhoneNumberLength = 30;
  static readonly IBANLength = 33;
  static readonly BICLength = 11;
  static readonly PersonTaxCodeLength = 20; // italian codice tessera sanitario/Steuernummer
  static readonly MaxNotesLength = 1000;

  static readonly MaxCurrencyLength = 12;
  static readonly MaxISOCodeLength = 5;
  static readonly MaxHexColorLength = 7;
}
