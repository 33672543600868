import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormSubmitActionsComponent } from '../form-submit-actions/form-submit-actions.component';

@Component({
  selector: 'ui-form-wrapper-block',
  template: `
    <div class="grid gap-5 mb-4 sm:grid-cols-1 md:grid-cols-4">
      <div class="col-auto" *ngIf="title">
        <h1 class="text-lg font-semibold">
          {{title}}
        </h1>
        <p *ngIf="helperText" class="m-0 text-xs text-gray-500 dark:text-gray-400">{{helperText}}</p>
      </div>
      <div class="text-xs" [ngClass]="{'col-span-3': title, 'col-span-4': !title}">
        <ng-content></ng-content>
      </div>
    </div>
    <ui-form-submit-actions
      (submitForm)="submitForm.emit($event)"
      (goBack)="back.emit()"
      [showSaveAndNew]="showSaveAndNew"
    ></ui-form-submit-actions>
  `,
  standalone: true,
  imports: [CommonModule, FormSubmitActionsComponent]
})

export class FormWrapperBlockComponent {
  @Input() title?: string;
  @Input() helperText?: string;
  @Input() showSaveAndNew = false;


  @Output() back: EventEmitter<void> = new EventEmitter<void>();
  @Output() submitForm: EventEmitter<boolean> = new EventEmitter<boolean>();
}
