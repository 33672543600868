import { Directive, HostBinding, Input } from '@angular/core';

export type ButtonTheme =
  | 'primary'
  | 'secondary'
  | 'accent'
  | 'danger'
  | 'light'
  | 'input';
export type ButtonSize = 'sm' | 'base' | 'lg';
export type ButtonType = 'standard' | 'icon';

@Directive({
  selector: '[uiButton]',
  standalone: true,
})
export class ButtonDirective {
  private readonly _mainAppButtonClass = 'app-btn';

  @HostBinding('class')
  private twClasses = `${this._mainAppButtonClass} `;

  private _theme: ButtonTheme = 'primary';
  @Input()
  set theme(value: ButtonTheme) {
    this._theme = value;
    this.updateClasses();
  }

  private _size: ButtonSize = 'base';
  @Input()
  set size(value: ButtonSize) {
    this._size = value;
    this.updateClasses();
  }

  private _buttonType: ButtonType = 'standard';
  @Input()
  set buttonType(value: ButtonType) {
    this._buttonType = value;
    this.updateClasses();
  }

  private _border = false;
  @Input()
  set border(value: boolean) {
    this._border = value;
    this.updateClasses();
  }

  private updateClasses() {
    this.twClasses = `${this._mainAppButtonClass} btn-${this._theme} btn-size-${
      this._size
    } btn-type-${this._buttonType} ${this._border ? 'btn-border' : ''}`;
  }
}
