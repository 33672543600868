import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AbstractCrudService } from '../base-service';
import { AbstractFormComponent } from './abstract-form.component';
import { DecisionDialogComponent } from '../ui/decision-dialog/decision-dialog.component';

@Component({ template: '' })
export abstract class AbstractPageFormComponent<T extends { id: string }>
  extends AbstractFormComponent<T>
  implements OnInit
{
  private readonly _activatedRouter: ActivatedRoute = inject(ActivatedRoute);
  private readonly _router: Router = inject(Router);

  canDeactivate(): Observable<unknown> {
    if (
      !this.entitySavedSuccessfully &&
      this.formHelperService.areControlTouchedOrDirty(this.form)
    ) {
      const dialogRef = this.dialogService.open(DecisionDialogComponent, {
        data: {
          message: this.translateService.instant(
            'Default.Dialog.confirmationMessage'
          ),
        },
      });
      return dialogRef.closed;
    } else {
      return of(true);
    }
  }

  abstract backRoute(): string;

  constructor(service: AbstractCrudService<T>) {
    super(service);
    this.entity = this._activatedRouter.snapshot.data['entity'];
    this.form = this.createForm();
  }

  ngOnInit(): void {
    if ('entity' in this._activatedRouter.snapshot.data) {
      this.form.patchValue(this._activatedRouter.snapshot.data['entity']);
    }
  }

  onEntitySaved(): void {
    this.goBack();
  }

  protected goBack() {
    const backRoute = this.backRoute();
    if (backRoute !== '') {
      this._router.navigate([backRoute]);
    }
  }
}
