import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Observable, map, startWith } from 'rxjs';
import { MomentUtils } from '../utils';

@Pipe({
    name: 'localizedDateFormat',
    standalone: true
})

export class LocalizedDateFormatPipe implements PipeTransform {

    constructor(private translateService: TranslateService) { }

    transform(value: string): Observable<string> {
        return this.translateService.onLangChange.pipe(startWith(value), map(() => {
            return value ? moment(value, 'YYYY-MM-DD').format(MomentUtils.getLocaleDateFormat(this.translateService.currentLang)) : '';
        }));
    }
}