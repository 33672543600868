import { ChangeDetectionStrategy, Component, Input, Optional, Self } from '@angular/core';
import { NgControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormControlComponent } from '../form-control/form-control.component';
import { InputDirective } from '../../../directives/input.directive';
import { FormErrorComponent } from '../form-error/form-error.component';
import { BaseInputComponent } from '../base-input.component';

export const types = ['text', 'email', 'password'] as const;
export type Types = typeof types[number];

@Component({
  selector: 'ui-form-text-input',
  standalone: true,
  templateUrl: './form-text-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveFormsModule, CommonModule, FormControlComponent, InputDirective, FormErrorComponent],
})
export class FormTextInputComponent extends BaseInputComponent<string> {
  @Input() inputType: Types = 'text';
  constructor(@Self() @Optional() control: NgControl) {
    super(control);
  }
}
