import { Inject, Injectable } from '@angular/core';
import { Observable, finalize } from 'rxjs';
import { ISearchService } from './interface/search-service.interface';
import { BaseService } from '../base-service/base.service';
import { BaseSearchParameter } from './parameter';
import { SearchResult } from '../interfaces';

@Injectable()
export class SearchService<SearchItemDTO, SearchParams>
  extends BaseService
  implements ISearchService<SearchItemDTO, SearchParams>
{
  constructor(@Inject('url') url: string, @Inject('server') server: string) {
    super(url, server);
  }

  search(
    parameter: BaseSearchParameter<SearchParams>
  ): Observable<SearchResult<SearchItemDTO>> {
    // check that the parameter object has no null or undefined values
    this.loadingService.setLoading(true);
    const url = `${this.serverUrl}/${this.apiUrl}/search`;
    return this.http
      .post<SearchResult<SearchItemDTO>>(url, parameter)
      .pipe(finalize(() => this.loadingService.setLoading(false)));
  }
}
