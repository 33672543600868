<aside
  id="logo-sidebar"
  class="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full bg-gray-900 text-gray-50 border-r border-gray-700 sm:translate-x-0"
  aria-label="Sidebar"
>
  <div class="h-full px-3 pb-4 overflow-y-auto bg-gray-900">
    <ul class="space-y-2 font-medium">
      <li
        *ngFor="
          let sidebarItem of sidebarItems;
          let i = index;
          trackBy: trackBy
        "
      >
        <a
          *ngIf="!sidebarItem.subMenu || sidebarItem.subMenu.length === 0"
          [routerLink]="sidebarItem.routerLink"
          routerLinkActive="bg-secondary-600 text-white"
          class="flex items-center p-2 text-sm hover:text-white rounded-lg hover:bg-secondary-600 group"
        >
          <span class="ml-3" sidebar-toggle-item>
            {{ sidebarItem.label }}
          </span>
        </a>
        <ng-container
          *ngIf="sidebarItem.subMenu && sidebarItem.subMenu.length > 0"
        >
          <button
            type="button"
            class="flex items-center w-full p-2 text-sm transition duration-75 rounded-lg hover:text-white hover:bg-secondary-600 group"
            (click)="toggleSubMenu(i)"
            [attr.aria-controls]="'dropdown-pages-' + i"
            [attr.aria-expanded]="openSubmenus[i] ? 'true' : 'false'"
          >
            <span
              class="flex-1 ml-3 text-left whitespace-nowrap"
              sidebar-toggle-item
            >
              {{ sidebarItem.label }}
            </span>
            <i
              class="fa-solid fa-chevron-down ml-2"
              [ngClass]="{ 'rotate-180': openSubmenus[i] }"
            ></i>
          </button>
          <ul
            id="dropdown-pages-{{ i }}"
            [ngClass]="{ hidden: !openSubmenus[i], block: openSubmenus[i] }"
            class="py-2 space-y-2"
          >
            <li
              *ngFor="let subMenuItem of sidebarItem.subMenu; trackBy: trackBy"
            >
              <a
                [routerLink]="subMenuItem.routerLink"
                class="flex items-center p-2 text-sm transition duration-75 rounded-lg pl-11 hover:text-white hover:bg-secondary-600 group"
              >
                {{ subMenuItem.label }}
              </a>
            </li>
          </ul>
        </ng-container>
      </li>
    </ul>
    <div
      class="absolute bottom-0 left-0 justify-center w-full p-4 space-x-4 bg-gray-900 lg:flex border-r border-gray-700"
      sidebar-bottom-menu
    >
      <footer class="text-center">
        <p class="text-sm text-gray-400 mb-4 md:mb-0">
          Version: {{ version }}
          <span
            class="bg-yellow-800 text-yellow-200 text-xs font-semibold px-2.5 rounded"
            *ngIf="
              environmentType !== environmentTypes.Production &&
              environmentType !== undefined
            "
          >
            {{
              environmentType === environmentTypes.Development
                ? 'Development'
                : 'Local'
            }}
          </span>
          <br />
          &copy; 2021 app. All rights reserved.
        </p>
      </footer>
    </div>
  </div>
</aside>
