import { Injectable } from "@angular/core";
import { IListPageService } from "./interface/list-page-service.interface";
import { Observable } from "rxjs";
import { SearchResult } from "../interfaces";
import { BaseSearchParameter } from "../search-service";


@Injectable()
export abstract class AbstractListPageService<T, SearchParams> implements IListPageService<T, SearchParams> {
  abstract search(parameter: BaseSearchParameter<SearchParams>): Observable<SearchResult<T>>;
  abstract delete(id: string): Observable<unknown>;
}
