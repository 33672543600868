import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector, Inject } from '@angular/core';
import {
  TOAST_CONFIG_TOKEN,
  TOAST_DATA,
  TOAST_REF,
  ToastConfig,
  ToastData,
} from './toast-config';
import { ToastRef } from './toast-ref';
import { ToastComponent } from './toast.component';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private lastToast: ToastRef;

  constructor(
    private overlay: Overlay,
    private parentInjector: Injector,
    @Inject(TOAST_CONFIG_TOKEN) private toastConfig: ToastConfig
  ) {}

  show(data: ToastData) {
    const positionStrategy = this._getPositionStrategy();
    const overlayRef = this.overlay.create({ positionStrategy });

    const toastRef = new ToastRef(overlayRef);
    this.lastToast = toastRef;

    const injector = this._getInjector(data, toastRef, this.parentInjector);
    const toastPortal = new ComponentPortal(ToastComponent, null, injector);

    overlayRef.attach(toastPortal);

    return toastRef;
  }

  private _getPositionStrategy() {
    return this.overlay
      .position()
      .global()
      .top(this._getPosition())
      .right(this.toastConfig.position?.right + 'px');
  }

  private _getPosition() {
    const lastToastIsVisible = this.lastToast && this.lastToast.isVisible();
    const position = lastToastIsVisible
      ? this.lastToast.getPosition().bottom
      : this.toastConfig.position?.top;

    return position + 'px';
  }

  private _getInjector(
    data: ToastData,
    toastRef: ToastRef,
    parentInjector: Injector
  ): Injector {
    return Injector.create({
      providers: [
        { provide: TOAST_DATA, useValue: data },
        { provide: TOAST_REF, useValue: toastRef },
      ],
      parent: parentInjector,
    });
  }
}
