import { Inject, Injectable } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import { ReadOnlyService } from './read-only.service';
import { ICrudService } from './interface/crud-service.interface';

@Injectable()
export class CrudService<T>
  extends ReadOnlyService<T>
  implements ICrudService<T>
{
  constructor(@Inject('url') url: string, @Inject('server') server: string) {
    super(url, server);
  }

  post(entity: T): Observable<T> {
    this.checkUrlIsSet();
    this.loadingService.setLoading(true);
    const url = `${this.serverUrl}/${this.apiUrl}`;
    return this.http
      .post<T>(url, entity)
      .pipe(finalize(() => this.loadingService.setLoading(false)));
  }

  update(entity: T): Observable<T> {
    this.checkUrlIsSet();
    this.loadingService.setLoading(true);
    const url = `${this.serverUrl}/${this.apiUrl}`;
    return this.http
      .put<T>(url, entity)
      .pipe(finalize(() => this.loadingService.setLoading(false)));
  }

  delete(id: string): Observable<unknown> {
    this.checkUrlIsSet();
    this.loadingService.setLoading(true);
    const url = `${this.serverUrl}/${this.apiUrl}/${id}`;
    return this.http
      .delete(url)
      .pipe(finalize(() => this.loadingService.setLoading(false)));
  }
}
