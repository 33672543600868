import { Component, OnInit, Inject } from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { toastAnimations, ToastAnimationState } from './toast-animation';
import {
  ToastData,
  TOAST_CONFIG_TOKEN,
  ToastConfig,
  TOAST_DATA,
  TOAST_REF,
} from './toast-config';
import { ToastRef } from './toast-ref';
import { takeUntil, timer } from 'rxjs';
import { BaseComponent } from '@budget-compass/frontend-core';

@Component({
  templateUrl: './toast.component.html',
  styleUrls: ['toast.component.scss'],
  animations: [toastAnimations.fadeToast],
})
export class ToastComponent extends BaseComponent implements OnInit {
  animationState: ToastAnimationState = 'default';

  public readonly toastConfig: ToastConfig;
  public readonly toastData: ToastData;

  constructor(
    @Inject(TOAST_REF) private toastRef: ToastRef,
    @Inject(TOAST_DATA) toastData: ToastData,
    @Inject(TOAST_CONFIG_TOKEN) toastConfig: ToastConfig
  ) {
    super();
    this.toastConfig = toastConfig;
    this.toastData = toastData;
  }

  ngOnInit() {
    timer(2500)
      .pipe(takeUntil(this._ngUnSubscribe))
      .subscribe(() => (this.animationState = 'closing'));
  }

  close() {
    this.toastRef.close();
  }

  onFadeFinished(event: AnimationEvent) {
    const { toState } = event;
    const isFadeOut = (toState as ToastAnimationState) === 'closing';
    const itFinished = this.animationState === 'closing';

    if (isFadeOut && itFinished) {
      this.close();
    }
  }
}
