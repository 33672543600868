import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormControlComponent } from '../form-control/form-control.component';
import { FormErrorComponent } from '../form-error/form-error.component';
import { BaseInputComponent } from '../base-input.component';
import moment from 'moment';
import { LocalizedDateFormatPipe } from '@budget-compass/frontend-core';
import { InputDirective } from '../../../directives';

type DateType = Date | string | moment.Moment | null | undefined;

@Component({
  selector: 'ui-form-date-picker',
  templateUrl: './form-date-picker.component.html',
  styles: [
    `
      input[type='date']::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
      }
    `,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    FormControlComponent,
    InputDirective,
    FormErrorComponent,
    TranslateModule,
    LocalizedDateFormatPipe,
  ],
})
export class FormDatePickerComponent
  extends BaseInputComponent<DateType>
  implements OnInit
{
  constructor(@Self() @Optional() control: NgControl) {
    super(control);
  }
  // @HostListener('document:click', ['$event'])
  // onClick(event: MouseEvent) {
  //   this.closeOnOutsideClick(event);
  // }

  // private readonly translateService: TranslateService =
  //   inject(TranslateService);
  // private MONTH_NAMES = MomentUtils.getMonths(
  //   this.translateService.currentLang
  // );
  // private DAYS = MomentUtils.getWeekdaysShort(
  //   this.translateService.currentLang
  // );
  // @Input() inline = false;
  @Input() dateFormat = 'YYYY-MM-DD';
  // @Input() showClearButton = true;

  // public get monthNames(): string[] {
  //   return this.MONTH_NAMES;
  // }
  // public get days(): string[] {
  //   return this.DAYS;
  // }
  // public showDatepicker = false;
  // public month!: number; // !: mean promis it will not be null, and it will definitely be assigned
  // public year!: number;
  // public no_of_days = [] as number[];
  // public blankdays = [] as number[];

  // constructor(
  //   @Self() @Optional() control: NgControl,
  //   private elementRef: ElementRef
  // ) {
  //   super(control);

  //   this.translateService.onLangChange
  //     .pipe(takeUntil(this._ngUnSubscribe))
  //     .subscribe(() => {
  //       this.MONTH_NAMES = MomentUtils.getMonths(
  //         this.translateService.currentLang
  //       );
  //       this.DAYS = MomentUtils.getWeekdaysShort(
  //         this.translateService.currentLang
  //       );
  //       // this.localeDateFormat = MomentUtils.getLocaleDateFormat(this.translateService.currentLang);
  //     });
  // }

  // override ngOnInit(): void {
  //   this._initControlValidation();
  //   this.initDate();
  //   this.getNoOfDays();
  // }

  override writeValue(value: DateType): void {
    if (value === null || value === undefined) {
      super.writeValue(value);
      return;
    }
    if (typeof value === 'string' || value instanceof Date) {
      value = moment(value).format(this.dateFormat);
    }

    if (moment.isMoment(value)) {
      value = value.format(this.dateFormat);
    }

    super.writeValue(value);
  }

  // initDate() {
  //   const today = moment(); // Current date
  //   this.month = today.month();
  //   this.year = today.year();
  // }

  // isToday(date: any) {
  //   const today = moment();
  //   const d = moment([this.year, this.month, date]);
  //   return today.isSame(d, 'day');
  // }

  // getDateValue(date: any) {
  //   const selectedDate = moment([this.year, this.month, date]);
  //   this.onChange(selectedDate.format(this.dateFormat));
  //   this.formControl.setValue(selectedDate.format(this.dateFormat));
  //   this.showDatepicker = false;
  //   this.onTouch();
  // }

  // getNoOfDays() {
  //   const daysInMonth = moment([this.year, this.month]).daysInMonth();

  //   const blankdaysArray = [];
  //   for (let i = 0; i < moment([this.year, this.month, 1]).day(); i++) {
  //     blankdaysArray.push(i);
  //   }

  //   const daysArray = [];
  //   for (let i = 1; i <= daysInMonth; i++) {
  //     daysArray.push(i);
  //   }

  //   this.blankdays = blankdaysArray;
  //   this.no_of_days = daysArray;
  // }

  // trackByIdentity = (index: number, item: any) => item;

  // clearDate() {
  //   if (this.formControl.value) {
  //     this.formControl.setValue(null);
  //     this.formControl.setErrors(null);
  //     this.formControl.updateValueAndValidity();
  //     this.onTouch();
  //   }
  // }

  // closeOnOutsideClick(event: MouseEvent) {
  //   const target = event.target as HTMLElement;
  //   if (!this.elementRef.nativeElement.contains(target)) {
  //     this.showDatepicker = false;
  //   }
  // }
}
