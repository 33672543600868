import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { APP_NAME } from '@budget-compass/frontend-core';

@Component({
  selector: 'ui-main-navbar',
  template: `
    <nav class="fixed top-0 z-50 w-full bg-primary-500 text-gray-50">
      <div class="px-3 py-3 lg:px-5 lg:pl-3">
        <div class="flex items-center justify-between">
          <div class="flex items-center justify-start rtl:justify-end">
            <button
              data-drawer-target="logo-sidebar"
              data-drawer-toggle="logo-sidebar"
              aria-controls="logo-sidebar"
              type="button"
              class="inline-flex items-center p-2 text-sm rounded-lg hover:bg-gray-100/30 md:hidden"
            >
              <span class="sr-only">Open sidebar</span>
              <i class="fa-solid fa-bars"></i>
            </button>
            <a [routerLink]="['/secure']" class="flex ms-2 md:me-24">
              <span
                class="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap"
              >
                {{ appName }}
              </span>
            </a>
          </div>
          <div class="flex items-center">
            <div class="flex items-center ms-3">
              <ng-content select="[data-navbar-content]"></ng-content>
            </div>
          </div>
        </div>
      </div>
    </nav>
  `,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RouterModule],
})
export class MainNavbarComponent {
  public readonly appName: string;

  constructor(@Inject(APP_NAME) appName: string) {
    this.appName = appName;
  }
}
