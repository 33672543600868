export enum BaseApiError {
  UnhandledError = 0,
  NullArgumentError = 1,
  //Authentication
  UserNotConfirmed = 1500,
  NotAuthorizedException = 1501,
  CodeMismatchException = 1502,
  UsernameExists = 1503,
  InvalidUsernameOrPassword = 1504,
  PasswordResetRequired = 1505,
  CodeMismatch = 1506,
  ExpiredCode = 1507,
  InvalidPassword = 1508,
  UserNotFound = 1509,
  NewPasswordRequired = 1510,
  //
  CannotDeleteEntityIsReferencedError = 1000,
  EntityNotFoundError = 1010,
  DuplicateEntityError = 1020,
  DataIntegrityError = 1030,
  ChangePasswordError = 1040,
  PasswordAlreadyUsed = 1041,
  PasswordDoesNotMeetRequirements = 1042,
  IoException = 1050,
  InsufficientPermissions = 1060,
  PayloadTooLargeError = 1080,
  DuplicateUserError = 2000,
  UserDeactivated = 2001,
  UserMissingInIdentity = 2002,
  NotSupportedImageTypeError = 2004,
  ValidationError = 2005,
  InvalidFileTypeOrContentError = 3000,
  UnableUseArchive = 3003,
  CircularReference = 3004,
}
