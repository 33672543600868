// async-loader.component.ts
import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, TemplateRef } from '@angular/core';
import { BaseApiError } from '@budget-compass/frontend-core';
import { Observable, catchError, of } from 'rxjs';

@Component({
  selector: 'ui-async-loader',
  template: `
    <ng-container *ngIf="data$ | async as data; else loading">
      <ng-template
        [ngTemplateOutlet]="contentTemplate!"
        [ngTemplateOutletContext]="{ data: data }"
      ></ng-template>
    </ng-container>

    <ng-template #loading>
      <div
        class="w-24 h-2 bg-gray-200 rounded-full dark:bg-gray-700"
        *ngIf="!error"
      ></div>
      <span
        class="bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300"
        *ngIf="error"
      >
        <ng-container [ngSwitch]="error">
          <span *ngSwitchCase="apiError.EntityNotFoundError">Not found</span>
          <span *ngSwitchDefault>Error</span>
        </ng-container>
      </span>
    </ng-template>
  `,
  standalone: true,
  imports: [CommonModule],
})
export class AsyncLoaderComponent implements OnChanges {
  @Input() obs: Observable<unknown> | undefined;
  @Input() contentTemplate: TemplateRef<unknown> | undefined;

  public readonly apiError = BaseApiError;
  public data$?: Observable<unknown>;
  public error?: BaseApiError;

  ngOnChanges() {
    if (this.obs) {
      this.error = undefined;
      this.data$ = this.obs.pipe(
        catchError((error: HttpErrorResponse) => {
          // Handle error here, you can log it or take appropriate action
          this.error = error.error.error as BaseApiError;
          // Return a fallback value or empty observable
          return of(undefined); // You can return any fallback value or empty observable here
        })
      );
    }
  }
}
