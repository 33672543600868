import { Inject, Injectable } from '@angular/core';
import { finalize, Observable, shareReplay } from 'rxjs';
import { IReadOnlyService } from './interface/read-only-service.iterface';
import { BaseService } from './base.service';

@Injectable()
export class ReadOnlyService<T>
  extends BaseService
  implements IReadOnlyService<T>
{
  private _getCache: { [id: string]: Observable<T> } = {};
  constructor(@Inject('url') url: string, @Inject('server') server: string) {
    super(url, server);
  }

  get(id: string, skipCache = true): Observable<T> {
    this.checkUrlIsSet();
    if (this._getCache[id] && !skipCache) {
      return this._getCache[id];
    }
    this.loadingService.setLoading(true);
    const url = `${this.serverUrl}/${this.apiUrl}/${id}`;
    const request = this.http.get<T>(url).pipe(
      finalize(() => this.loadingService.setLoading(false)),
      shareReplay(1) // Share the result and replay it to subsequent subscribers
    );
    if (!skipCache) {
      this._getCache[id] = request;
    }
    return request;
  }

  getAll(): Observable<T[]> {
    this.checkUrlIsSet();
    this.loadingService.setLoading(true);
    const url = `${this.serverUrl}/${this.apiUrl}`;
    return this.http
      .get<T[]>(url)
      .pipe(finalize(() => this.loadingService.setLoading(false)));
  }
}
