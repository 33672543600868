import { PipeTransform, Injectable, Pipe } from '@angular/core';
import { AbstractReadOnlyService } from '../base-service';
import { Observable } from 'rxjs';

@Pipe({
  name: 'fetchDataById',
  standalone: true
})
@Injectable()
export class FetchDataByIdPipe implements PipeTransform {

  transform(id: string, service: AbstractReadOnlyService<unknown>): Observable<unknown> | null {
    if (!id) {
      return null;
    }
    return service.get(id, false);
  }
}
