import { Directive, ElementRef, HostListener } from '@angular/core';
import { DropdownOptions, Dropdown } from 'flowbite';

@Directive({
  selector: '[uiDropdownButton]',
  standalone: true
})
export class DropdownButtonDirective {

  @HostListener('click', ['$event']) onClick(event: MouseEvent) {
    const dropdownMenuId = this.el.nativeElement.getAttribute('data-dropdown-toggle');
    const dropdownMenu = this.el.nativeElement.parentElement?.querySelector(`#${dropdownMenuId}`);
    if (dropdownMenu) {
      const options: DropdownOptions = {
        placement: 'left-start',
        triggerType: 'click',
        offsetSkidding: 0,
        offsetDistance: 10,
        delay: 300,
      };
      new Dropdown(dropdownMenu, this.el.nativeElement, options).show();
    }
    event.preventDefault();
    event.stopPropagation();
  }

  constructor(private el: ElementRef) { }

}
