<div
  class="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow"
  role="alert"
  [@fadeAnimation]="{
    value: animationState,
    params: {
      fadeIn: toastConfig.animation?.fadeIn,
      fadeOut: toastConfig.animation?.fadeOut
    }
  }"
  (@fadeAnimation.done)="onFadeFinished($event)"
>
  <div
    class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg"
    [ngClass]="{
      'text-green-500 bg-green-100': toastData.type === 'success',
      'text-red-500 bg-red-100': toastData.type === 'error',
      'text-yellow-500 bg-yellow-100': toastData.type === 'warning',
      'text-blue-500 bg-blue-100': toastData.type === 'info'
    }"
  >
    <i
      class="fa-solid"
      [ngClass]="{
        'fa-check': toastData.type === 'success',
        'fa-exclamation': toastData.type === 'error',
        'fa-exclamation-triangle': toastData.type === 'warning',
        'fa-info-circle': toastData.type === 'info'
      }"
    ></i>
    <span class="sr-only">Check icon</span>
  </div>
  <div class="mx-3 text-sm font-normal min-w-10">{{ toastData.text }}</div>
  <button
    type="button"
    class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8"
    data-dismiss-target="#toast-success"
    aria-label="Close"
    (click)="close()"
  >
    <span class="sr-only">Close</span>
    <svg
      class="w-3 h-3"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
      />
    </svg>
  </button>
</div>
