import { OverlayModule } from '@angular/cdk/overlay';
import {
  NgModule,
  ModuleWithProviders,
  SkipSelf,
  Optional,
} from '@angular/core';
import { defaultToastConfig, TOAST_CONFIG_TOKEN } from './toast-config';
import { ToastComponent } from './toast.component';
import { ToastService } from './toast.service';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [OverlayModule, CommonModule],
  declarations: [ToastComponent],
})
export class ToastModule {
  constructor(@Optional() @SkipSelf() parentModule: ToastModule) {
    throwIfAlreadyLoaded(parentModule, 'ToastModule');
  }
  public static forRoot(
    config = defaultToastConfig
  ): ModuleWithProviders<ToastModule> {
    return {
      ngModule: ToastModule,
      providers: [
        {
          provide: TOAST_CONFIG_TOKEN,
          useValue: { ...defaultToastConfig, ...config },
        },
        ToastService,
      ],
    };
  }
}

const throwIfAlreadyLoaded = (
  parentModule: unknown,
  moduleName: string
): void => {
  if (parentModule) {
    throw new Error(
      `${moduleName} has already been loaded. Import Toast module in the AppModule only.`
    );
  }
};
