import { Inject, Injectable } from '@angular/core';
import { IListPageService } from './interface/list-page-service.interface';
import { Observable, finalize } from 'rxjs';
import { SearchService } from '../search-service';

@Injectable()
export class ListPageService<SearchItemDTO, SearchParams>
  extends SearchService<SearchItemDTO, SearchParams>
  implements IListPageService<SearchItemDTO, SearchParams>
{
  constructor(@Inject('url') url: string, @Inject('server') server: string) {
    super(url, server);
  }

  delete(id: string): Observable<unknown> {
    this.checkUrlIsSet();
    this.loadingService.setLoading(true);
    const url = `${this.serverUrl}/${this.apiUrl}/${id}`;
    return this.http
      .delete(url)
      .pipe(finalize(() => this.loadingService.setLoading(false)));
  }
}
