import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  CustomErrorMessage,
  ParseErrorMessagePipe,
} from '../parse-error-message.pipe';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'ui-form-error',
  template: `
    <p class="text-red-500 text-xs mt-1" *ngIf="!isValid">
      <span [innerHtml]="hasErrors | parseErrorMessage : errorMessages"></span>
    </p>
  `,
  standalone: true,
  imports: [CommonModule, ParseErrorMessagePipe],
})
export class FormErrorComponent {
  @Input() isValid: boolean | null = false;
  @Input() hasErrors: ValidationErrors | undefined = undefined;
  @Input() errorMessages?: CustomErrorMessage;
}
