import { NgClass } from '@angular/common';
import { Component, Directive, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

const modalSize = ['sm', 'lg', 'xl', 'default'] as const;
type ModalSize = typeof modalSize[number];

@Directive({
  selector: '[uiModalBody]',
  standalone: true,
})
export class ModalBodyDirective {
  @HostBinding('class') class = 'p-4 md:p-5 space-y-4';
}

@Directive({
  selector: '[uiModalFooter]',
  standalone: true,
})
export class ModalFooterDirective {
  @HostBinding('class') class = 'flex items-center p-4 border-t border-gray-200 rounded-b justify-end';
}

@Component({
  selector: 'ui-modal-content',
  templateUrl: './modal-content.component.html',
  standalone: true,
  imports: [TranslateModule, NgClass],
})

export class ModalContentComponent {
  @Input() title = '';
  @Input() size: ModalSize = 'default';

  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  public readonly modalSize = modalSize;
}
