import { inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { LoadingService } from '../services';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private readonly _loadingService: LoadingService = inject(LoadingService);
  private totalRequests = 0;

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.indexOf('/assets/i18n/') === -1) {
      this.totalRequests++;
      if (!this._loadingService.isLoading) {
        this._loadingService.setLoading(true);
      }
      return next.handle(request).pipe(
        finalize(() => {
          this.totalRequests--;
          if (this.totalRequests == 0) {
            this._loadingService.setLoading(false);
          }
        })
      );
    }
    return next.handle(request);
  }
}
