import { Injectable } from "@angular/core";
import { IReadOnlyService } from "./interface/read-only-service.iterface";
import { Observable } from "rxjs";


@Injectable()
export abstract class AbstractReadOnlyService<T> implements IReadOnlyService<T>{
  abstract get(id: string, skipCache: boolean): Observable<T>;
  abstract getAll(): Observable<T[]>;
}
