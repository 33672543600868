import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_ENV, IEnvironment } from '@budget-compass/frontend-core';
import { Observable, map, tap } from 'rxjs';
import store from 'store2';
import { AccessTokenDTO } from './dtos/access-token.dto';
import { ACCESS_TOKEN_KEY } from '@framework/shared/core-util-types';
export interface IAuthService {
  authenticateUser(
    username: string,
    password: string
  ): Observable<AccessTokenDTO>;
  refreshToken(): Observable<AccessTokenDTO>;
  signOut(): void;
}

@Injectable({ providedIn: 'root' })
export class AuthService implements IAuthService {
  constructor(
    private _http: HttpClient,
    @Inject(APP_ENV) private _appEnv: IEnvironment
  ) {}

  public authenticateUser(
    email: string,
    password: string
  ): Observable<AccessTokenDTO> {
    return this._http
      .post<AccessTokenDTO>(
        `${this._appEnv.authApiUrl}/authentication/login`,
        {
          email: email,
          password: password,
        },
        { withCredentials: false }
      )
      .pipe(
        map((token: AccessTokenDTO) => {
          store(ACCESS_TOKEN_KEY, token.access_token);
          return token;
        })
      );
  }

  refreshToken(): Observable<AccessTokenDTO> {
    return this._http.post<AccessTokenDTO>(
      `${this._appEnv.authApiUrl}/authentication/refresh`,
      {}
    );
  }

  signOut() {
    return this._http
      .post(`${this._appEnv.authApiUrl}/authentication/logout`, {})
      .pipe(
        tap(() => {
          store.clear();
        })
      );
  }

  getToken() {
    return store(ACCESS_TOKEN_KEY);
  }
}

// https://github.com/bradleybeighton/authentication-authorization-example/blob/master/apps/auth-ui/src/app/guards/auth.guard.ts
