import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, NgForm, FormGroup } from '@angular/forms';
import { Subject, Observable, switchMap, startWith, map, mergeMap } from 'rxjs';
import { BaseComponent } from '../base-component/base.component';
import { AbstractListPageService } from '../base-service';
import { SearchResult } from '../interfaces';
import { BaseSearchParameter, SearchParameter } from '../search-service';
import { IBaseModel } from '@framework/shared/core-util-types';

type TBaseModel<T> = T extends IBaseModel ? T : never;
type FormType<T> = {
  [K in keyof T]: FormControl<T[K]>;
};

type ActionsKey = 'actions';

export type DisplayedColumns<T> = (keyof T | ActionsKey)[];

@Component({
  template: '',
})
export abstract class AbstractListPageComponent<T, SearchParams>
  extends BaseComponent
  implements OnInit
{
  public readonly startFilterEvent: Subject<void> = new Subject<void>();
  private get _startFilterEvent$() {
    return this.startFilterEvent.asObservable();
  }

  @ViewChild('filterForm', { static: true }) filterForm?: NgForm;

  protected data$?: Observable<SearchResult<T>>;

  public readonly searchForm?: FormGroup<FormType<SearchParams>>;

  public readonly displayedColumns: DisplayedColumns<T> = [];

  public abstract createSearchForm(): FormGroup<FormType<SearchParams>>;
  public abstract createDisplayedColumns(): DisplayedColumns<T>;

  private _searchParameters: BaseSearchParameter<SearchParams> =
    new BaseSearchParameter<SearchParams>();

  public get pageSize(): number {
    return this._searchParameters.pageSize;
  }

  public set pageSize(value: number) {
    this._searchParameters.pageSize = value;
  }

  public get page(): number {
    return this._searchParameters.page;
  }

  constructor(protected _service: AbstractListPageService<T, SearchParams>) {
    super();
    this.overridePageSize();
    this.displayedColumns = this.createDisplayedColumns();
    // check if "actions" column is present in the displayed columns, if not add it to the end of the list
    if (this.displayedColumns.indexOf('actions') === -1) {
      this.displayedColumns.push('actions');
    }

    this.searchForm = this.createSearchForm();
  }

  ngOnInit(): void {
    this.initSearchProcess();
  }

  public deleteItem(id: string) {
    // TODO: add propper error handling
    this.data$ = this._service.delete(id).pipe(
      switchMap(() => {
        return this._service.search(this._searchParameters);
      })
    );
  }

  public onPageChange(page: number) {
    this._searchParameters.page = page;
    this.data$ = this._service.search(this._searchParameters);
  }

  protected initSearchProcess() {
    if (this.searchForm) {
      this.data$ = this._startFilterEvent$.pipe(
        startWith(null),
        map(
          () => this.searchForm?.getRawValue() as SearchParameter<SearchParams>
        ),
        // remove all null and undefined values from the search form
        map((res: SearchParameter<SearchParams>) =>
          Object.entries(res)
            .filter(([, value]) => value !== null && value !== undefined)
            .reduce(
              (acc, [key, value]) => ({ ...acc, [key]: value }),
              {} as SearchParameter<SearchParams>
            )
        ),
        mergeMap((searchItems: SearchParameter<SearchParams>) => {
          this._searchParameters.searchItems = searchItems;
          return this._service.search(this._searchParameters);
        })
      );
    }
  }

  protected trackBy(index: number, name: TBaseModel<T>): string {
    return name.id;
  }

  /**
   * @summary This method is used to override the default page size
   * @description To override the page size use the following code:
   * public override overridePageSize() {
   *  this.pageSize = 20;
   * }
   * @returns void
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public overridePageSize() {}
}
