import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[uiLink]',
  standalone: true,
})
export class LinkDirective {
  private readonly _mainAppLinkClass = 'app-link';

  @HostBinding('class')
  private _twClasses = this._mainAppLinkClass;
}
