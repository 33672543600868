import { LogLevel } from "../logging";

export enum EnvironmentType {
  Development,
  Production,
  Local,
}

export interface IEnvironment {
  production: boolean;
  apiUrl: string;
  authApiUrl: string;
  logLevel: LogLevel,
  environmentType: EnvironmentType,
  version: string;
}
