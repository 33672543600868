import { Component } from '@angular/core';

@Component({
  selector: 'ui-form-wrapper',
  template: `
  <div class="container mx-auto">
    <div class="form-wrapper sm:max-w-fit md:max-w-screen-md mx-auto">
      <div class="form-wrapper__title">
        <ng-container *ngTemplateOutlet="titleTemplate"></ng-container>
      </div>
      <div class="form-wrapper__content"> <!-- Possible class : sm:max-w-fit md:max-w-screen-lg mx-auto -->
        <ng-content></ng-content>
        <ng-container *ngTemplateOutlet="actionsTemplate"></ng-container>
      </div>
    </div>
  </div>

  <ng-template #titleTemplate>
    <ng-content select="[formWrapperTitle]"></ng-content>
  </ng-template>
  <ng-template #actionsTemplate>
    <ng-content select="[formWrapperActions]"></ng-content>
  </ng-template>
  `,
})

export class FormWrapperComponent { }
