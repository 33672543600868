<ui-form-control
  [label]="label"
  [id]="id"
  [isRequired]="hasRequiredValidator ?? false"
>
  <div
    class="grid grid-cols-4 gap-0 w-full text-sm font-medium text-gray-900 bg-white"
  >
    <div
      class="border-r border-gray-200 flex items-center"
      *ngFor="let choice of choices; let i = index"
    >
      <div class="w-full flex items-center ps-3">
        <input
          (change)="onCheckboxChange(choice.id)"
          [class.invalid-input]="(isControlValid$ | async) === false"
          [id]="'inline-checkbox-' + i"
          type="checkbox"
          [checked]="selectedChoices.includes(choice.id)"
          class="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 rounded focus:ring-primary-500 focus:ring-2"
        />
        <label
          [for]="'inline-checkbox-' + i"
          class="w-full py-3 ms-2 text-sm font-medium text-gray-900"
        >
          {{ choice.label }}
        </label>
      </div>
    </div>
  </div>
  <ui-form-error
    [isValid]="isControlValid$ | async"
    [errorMessages]="errorMessages"
    [hasErrors]="hasErrors"
  ></ui-form-error>
</ui-form-control>
