<ui-form-control
  [label]="label"
  [id]="id"
  [isRequired]="hasRequiredValidator ?? false"
>
  <div class="relative">
    <div
      *ngIf="(!hasRequiredValidator ?? false) && formControl.value !== null"
      class="absolute grid w-5 h-5 place-items-center text-blue-gray-500 top-2/4 right-4 -translate-y-2/4 z-10 cursor-pointer"
      (click)="formControl.reset()"
    >
      <i class="fa-solid fa-circle-xmark bg-white"></i>
    </div>
    <input
      type="date"
      [formControl]="formControl"
      [id]="id"
      [name]="name"
      uiInput
      [size]="size"
      [class.disabled]="formControl.disabled"
      [class.invalid-input]="(isControlValid$ | async) === false"
      (blur)="onTouch()"
    />
    <!-- <div class="absolute top-1 right-0 px-3 py-1">
      <i
        *ngIf="showClearButton"
        class="fas fa-times cursor-pointer mr-1"
        (click)="clearDate()"
        [ngClass]="{ 'text-gray-400': !formControl.value }"
      ></i>
      <i
        class="fas fa-calendar-days cursor-pointer fa-lg"
        (click)="showDatepicker = !showDatepicker"
      ></i>
    </div> -->
    <!-- <div
      class="bg-white mt-12 rounded-lg shadow p-4 absolute top-0 left-0 z-50"
      style="width: 17rem"
      [hidden]="!showDatepicker"
      (keydown.away)="showDatepicker = false"
    >
      <div class="flex justify-between items-center mb-2">
        <div>
          <span class="text-lg font-bold text-gray-800">
            {{ monthNames[month] }}
          </span>
          <span class="ml-1 text-lg text-gray-600 font-normal">{{ year }}</span>
        </div>
        <div>
          <button
            type="button"
            class="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
            [ngClass]="{ 'cursor-not-allowed opacity-25': month === 0 }"
            [disabled]="month === 0 ? true : false"
            (click)="month = month - 1"
            (click)="getNoOfDays()"
          >
            <svg
              class="h-6 w-6 text-gray-500 inline-flex"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <button
            type="button"
            class="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
            [ngClass]="{
              'cursor-not-allowed opacity-25': month === 11
            }"
            [disabled]="month === 11 ? true : false"
            (click)="month = month + 1"
            (click)="getNoOfDays()"
          >
            <svg
              class="h-6 w-6 text-gray-500 inline-flex"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      </div>

      <div class="flex flex-wrap mb-3 -mx-1">
        <ng-container *ngFor="let day of days; let index = index">
          <div style="width: 14.26%" class="px-1">
            <div class="text-gray-800 font-medium text-center text-xs">
              {{ day }}
            </div>
          </div>
        </ng-container>
      </div>

      <div class="flex flex-wrap -mx-1">
        <ng-container *ngFor="let blankday of blankdays">
          <div
            style="width: 14.28%"
            class="text-center border-none p-1 border-transparent text-sm"
          ></div>
        </ng-container>
        <ng-container
          *ngFor="
            let date of no_of_days;
            let dateIndex = index;
            trackBy: trackByIdentity
          "
        >
          <div style="width: 14.28%" class="px-1 mb-1">
            <div
              (click)="getDateValue(date)"
              class="cursor-pointer text-center text-sm rounded-full leading-loose transition ease-in-out duration-100"
              [ngClass]="{
                'bg-blue-500 text-white': isToday(date) === true,
                'text-gray-700 hover:bg-blue-200': isToday(date) === false
              }"
            >
              {{ date }}
            </div>
          </div>
        </ng-container>
      </div>
    </div> -->
  </div>

  <ui-form-error
    [isValid]="isControlValid$ | async"
    [errorMessages]="errorMessages"
    [hasErrors]="hasErrors"
  ></ui-form-error>
</ui-form-control>
