<div class="flex items-center" *ngIf="showSaveAndNew">
  <input
    id="save-and-new-checkbox"
    [(ngModel)]="saveAndNew"
    type="checkbox"
    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500focus:ring-2"
  />
  <label
    for="save-and-new-checkbox"
    class="ms-2 text-xs font-medium text-gray-900 dark:text-gray-300"
  >
    {{ 'Default.modal.action.saveAndNew' | translate }}
  </label>
</div>

<button type="button" (click)="goBack.emit()" appButton theme="secondary">
  {{ 'Default.form.cancel' | translate }}
</button>
<button
  type="button"
  (click)="submitForm.emit(saveAndNew)"
  appButton
  theme="primary"
>
  {{ 'Default.form.save' | translate }}
</button>
