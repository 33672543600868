import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { initFlowbite } from 'flowbite';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  constructor(translateService: TranslateService) {
    // Set default language
    translateService.setDefaultLang('en');
    translateService.use('en');
  }

  ngOnInit(): void {
    // Initialize Flowbite
    initFlowbite();
  }
}
