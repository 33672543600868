import { Directive, HostBinding, Input } from '@angular/core';

export type InputTheme = 'primary' | 'secondary';
export type InputSize = 'sm' | 'base' | 'lg';

@Directive({
  selector: '[uiInput]',
  standalone: true,
})
export class InputDirective {
  private readonly _mainAppInputClass = 'app-input';

  @HostBinding('class')
  private twClasses = `${this._mainAppInputClass} base primary`;

  private _theme: InputTheme = 'primary';
  @Input()
  set theme(value: InputTheme) {
    this._theme = value;
    this.updateClasses();
  }

  private _size: InputSize = 'base';
  @Input()
  set size(value: InputSize) {
    this._size = value;
    this.updateClasses();
  }

  private _disabled = false;
  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
    this.updateClasses();
  }

  private updateClasses() {
    const themeClass = this._disabled ? 'disabled' : this._theme;
    this.twClasses = `${this._mainAppInputClass} ${themeClass} ${this._size}`;
  }
}
