import { Pipe, PipeTransform } from '@angular/core';
import { SelectInputOption } from './select-input-option';

@Pipe({
  name: 'sortOptions',
  standalone: true,
})
export class SortOptionsPipe implements PipeTransform {
  transform(
    options: SelectInputOption[],
    sortOptions = true
  ): SelectInputOption[] {
    if (!sortOptions) {
      return options;
    }
    return options.sort((a: SelectInputOption, b: SelectInputOption) => {
      return a.label.trim().localeCompare(b.label.trim());
    });
  }
}
