import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { LoadingService } from '../services';

export class BaseService {
  protected readonly http: HttpClient = inject(HttpClient);
  protected readonly loadingService: LoadingService = inject(LoadingService);
  protected apiUrl = '';
  protected serverUrl = '';

  constructor(protected url: string, protected server: string) {
    this.apiUrl = `${url}`;
    this.serverUrl = `${server}`;
  }

  protected checkUrlIsSet(): void {
    if (this.apiUrl.length === 0) {
      throw new Error(
        'Url not set! Please call setUrl before calling this method'
      );
    }
    if (this.serverUrl.length === 0) {
      throw new Error(
        'Server Url not set! Please call setUrl before calling this method'
      );
    }
  }
}
