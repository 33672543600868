import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarItem } from './sidebar-item';
import { RouterModule } from '@angular/router';
import { EnvironmentType } from '@budget-compass/frontend-core';

@Component({
  selector: 'ui-sidebar',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {
  @Input() sidebarItems: SidebarItem[] = [];
  @Input() version?: string;
  @Input() environmentType?: EnvironmentType;

  public readonly environmentTypes = EnvironmentType;

  public openSubmenus: { [key: string]: boolean } = {};

  // Method to toggle the submenu
  toggleSubMenu(index: number): void {
    this.openSubmenus[index] = !this.openSubmenus[index];
  }

  trackBy(index: number): number {
    return index;
  }
}
