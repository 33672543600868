export const EmailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$';
export const PhonePattern = '[- +()0-9]{6,}';
export const UrlPattern = '^https?://(.*)';
export const MaxShortCodeLength = 3;
export const MaxPostalCodeLength = 10;
export const MaxTaxCodeLength = 25;
export const VatIdCodeLength = 25;
export const MaxCodeLength = 50;
export const MaxNameLength = 100;
export const MinNameLength = 2;
export const MaxUriLength = 1024;
export const MinUriLength = 12;
export const MaxEmailLength = 100;
export const MaxAddressLength = 250;
export const MaxDescriptionLength = 500;
export const MaxWebSiteLength = 250;
export const PhoneLength = 15;
export const MaxPasswordLength = 200;
export const MaxTranslateableInvariantLanguageValueLength = 512;
export const MaxTranslatedStringLength = 512;
export const MaxPhonePrefixLength = 3;
export const MaxPhoneNumberLength = 16; // without prefix, so different from PhoneLength
export const IBANLength = 27;
export const BICLength = 8;
export const PersonTaxCodeLength = 15; // italian codice tessera sanitario/Steuernummer
export const ExactBoardCodeLength = 2;
export const MaxCommentLength = 1000;
