import moment from "moment";

export class MomentUtils {
    public static getMonths(locale: string): string[] {
        moment.locale(locale);
        return moment.months().map((month) => month.charAt(0).toUpperCase() + month.slice(1));
    }

    public static getWeekdaysShort(locale: string): string[] {
        moment.locale(locale);
        return moment.weekdaysShort();
    }

    public static getLocaleDateFormat(locale: string): string {
        moment.locale(locale);
        const dateFormat = moment().creationData().locale.longDateFormat('L');
        return dateFormat;
    }
}