<div
  class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-20"
>
  <div
    tabindex="-1"
    class="relative w-full max-h-full"
    [ngClass]="{
      'max-w-md': size === modalSize[0],
      'max-w-lg': size === modalSize[3],
      'max-w-4xl': size === modalSize[1],
      ' max-w-7xl': size === modalSize[2]
    }"
  >
    <!-- Modal content -->
    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
      <!-- Modal header -->
      <div
        class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600"
      >
        <h3 class="text-xl font-medium text-gray-900 dark:text-white">
          {{ title }}
        </h3>
        <button
          type="button"
          class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-xs w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
          (click)="closeModal.emit()"
        >
          <svg
            class="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span class="sr-only">{{ 'Default.closeDialog' | translate }}</span>
        </button>
      </div>
      <!-- Modal body -->
      <ng-content selector="div[appModalBody]"></ng-content>
      <!-- Modal footer -->
      <ng-content selector="div[appModalFooter]"></ng-content>
    </div>
  </div>
</div>
