import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  storeString(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  getString(key: string): string | undefined {
    return localStorage.getItem(key) || undefined;
  }

  storeBool(key: string, value: boolean) {
    localStorage.setItem(key, value ? 'true' : 'false');
  }

  getBool(key: string): boolean {
    return localStorage.getItem(key) === 'true';
  }

  clear() {
    localStorage.clear();
  }
}
