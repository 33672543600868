/* eslint-disable no-restricted-syntax */
import { Inject, Injectable } from '@angular/core';
import { LogLevel } from './log-level';
import { APP_ENV } from '../constants';
import { IEnvironment } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {

  constructor(@Inject(APP_ENV) private appEnv: IEnvironment) { }

  private level: LogLevel = this.appEnv.logLevel;

  private writeLog(message: string, lvl: LogLevel): void {
    if (this._consoleAvailable() && this._isEnabled(lvl)) {
      switch (lvl) {
        case LogLevel.debug:
          console.debug(message);
          break;
        case LogLevel.info:
          console.info(message);
          break;
        case LogLevel.error:
          console.error(message);
          break;
      }
    }
  }

  private _consoleAvailable(): boolean {
    return typeof window.console !== 'undefined';
  }

  private _isEnabled(level: LogLevel): boolean {
    return this.level >= level;
  }

  debug(message: string): void {
    this.writeLog(message, LogLevel.debug);
  }

  info(message: string): void {
    this.writeLog(message, LogLevel.info);
  }

  error(message: string): void {
    this.writeLog(message, LogLevel.error);
  }

}
