import { Component, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";

@Component({
  template: '',
})
export class BaseComponent implements OnDestroy {
  protected readonly _ngUnSubscribe: Subject<boolean> = new Subject();

  ngOnDestroy(): void {
    this._ngUnSubscribe.next(false);
  }
}
