import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Self,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgControl, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, tap, takeUntil } from 'rxjs';
import { FormControlComponent } from '../../form-control/form-control.component';
import { InputDirective } from '../../../../directives/input.directive';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormErrorComponent } from '../../form-error/form-error.component';
import { BaseInputComponent } from '../../base-input.component';
import { SortOptionsPipe } from '../sort-options.pipe';
import { SelectInputOption } from '../select-input-option';

@Component({
  selector: 'ui-form-enum-select-input[translateKey][enum]',
  standalone: true,
  templateUrl: './form-enum-select-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    FormControlComponent,
    InputDirective,
    FormErrorComponent,
    SortOptionsPipe,
    TranslateModule,
  ],
})
export class FormEnumSelectInputComponent
  extends BaseInputComponent<string | number | null>
  implements OnInit, OnChanges
{
  @Input() inline = false;
  @Input({ required: true }) enum: Record<string, string | number>;
  @Input({ required: true }) translateKey: string;
  @Input() hideAllValue = false;
  @Input() hidePlaceholder = false;

  public options: SelectInputOption[] = [];

  constructor(
    @Self() @Optional() control: NgControl,
    private _translate: TranslateService
  ) {
    super(control);
  }

  // in case the enum input changes we need to update the options
  ngOnChanges(changes: SimpleChanges): void {
    if ('enum' in changes && changes['enum'].isFirstChange() === false) {
      this._initOptions();
    }
  }

  override ngOnInit(): void {
    this._initControlValidation();
    this._initOptions();

    if (this.hideAllValue === false) {
      this.options.unshift({
        label: this._translate.instant('Default.option.All'),
        id: null,
      });
    }

    this.formControl.valueChanges
      .pipe(
        debounceTime(200),
        tap((value: string | number | null) => {
          if (value === null || value === undefined || value === 'null') {
            this.onChange(null);
          } else {
            if (!isNaN(+value)) {
              this.onChange(+value);
            } else {
              this.onChange(value);
            }
          }
        }),
        takeUntil(this._ngUnSubscribe)
      )
      .subscribe();
  }

  private _initOptions(): void {
    this.options = Object.keys(this.enum as object)
      .filter((v) => isNaN(Number(v)))
      .map((label) => ({
        label: this._translate.instant(
          `enum.${this.translateKey}.` + label
        ) as string,
        id: this.enum[label],
      }));
  }
}
