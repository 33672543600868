import { InjectionToken, TemplateRef } from '@angular/core';
import { ToastRef } from './toast-ref';

export class ToastData {
  type: ToastType;
  text?: string;
  template?: TemplateRef<unknown>;
  templateContext?: object;
}

export type ToastType = 'warning' | 'info' | 'success' | 'error';

export interface ToastConfig {
  position?: {
    top: number;
    right: number;
  };
  animation?: {
    fadeOut: number;
    fadeIn: number;
  };
}

export const defaultToastConfig: ToastConfig = {
  position: {
    top: 20,
    right: 50,
  },
  animation: {
    fadeOut: 300,
    fadeIn: 300,
  },
};

export const TOAST_CONFIG_TOKEN = new InjectionToken<ToastConfig>(
  'TOAST_CONFIG'
);
export const TOAST_DATA = new InjectionToken<ToastData>('TOAST_DATA');
export const TOAST_REF = new InjectionToken<ToastRef>('TOAST_REF');
