import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgControl, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs';
import { FormControlComponent } from '../../form-control/form-control.component';
import { InputDirective } from '../../../../directives/input.directive';
import { FormErrorComponent } from '../../form-error/form-error.component';
import { BaseInputComponent } from '../../base-input.component';
import { SortOptionsPipe } from '../sort-options.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { SelectInputOption } from '../select-input-option';

@Component({
  selector: 'ui-form-multiple-select-input',
  standalone: true,
  templateUrl: './form-multiple-select-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    FormControlComponent,
    InputDirective,
    FormErrorComponent,
    SortOptionsPipe,
    TranslateModule,
  ],
})
export class FormMultipleSelectInputComponent
  extends BaseInputComponent<(string | number)[] | null>
  implements OnInit
{
  @Input() inline = false;
  @Input() hidePlaceholder = false;
  @Input() options: SelectInputOption[] = [];

  constructor(@Self() @Optional() control: NgControl) {
    super(control);
  }

  override ngOnInit(): void {
    this._initControlValidation();
    this.formControl.valueChanges
      .pipe(debounceTime(200), takeUntil(this._ngUnSubscribe))
      .subscribe((value: (string | number)[] | null) => {
        this.onChange(value);
      });
  }
}
