import { PaginationParameter } from './pagination.parameter';
import { SortOrder, SortParameter } from './sort.parameter';

export type SearchParameter<T> = {
  [K in keyof T]?: T[K];
};

export class BaseSearchParameter<T>
  implements PaginationParameter, SortParameter
{
  searchItems: SearchParameter<T> = {};
  page = 1;
  pageSize = 10;
  orderBy?: string;
  sortOrder?: SortOrder = SortOrder.DESC;
}
