import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class WithCredentialsInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.indexOf('/assets/i18n/') === -1) {
      // check if the request dont have withCredentials header
      if (!request.headers.has('withCredentials')) {
        request = request.clone({
          withCredentials: true,
        });
      }
    }
    return next.handle(request);
  }
}

