import { Injectable } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { Observable } from 'rxjs';
import { AbstractPageFormComponent } from '../abstract-components';
export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({ providedIn: 'root' })
export class PadingChangesGuard {
  canDeactivate: CanDeactivateFn<
    AbstractPageFormComponent<unknown & { id: string }>
  > = (
    component: AbstractPageFormComponent<unknown & { id: string }>
  ): Observable<boolean> => {
    return component.canDeactivate() as Observable<boolean>;
  };
}
