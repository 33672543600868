import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  Validators,
  NgControl,
  ControlValueAccessor,
} from '@angular/forms';
import { BaseComponent } from '@budget-compass/frontend-core';
import {
  noop,
  takeUntil,
  Observable,
  BehaviorSubject,
  pairwise,
  startWith,
} from 'rxjs';
import { InputSize } from '../../directives';

const controlStatus = ['VALID', 'INVALID'] as const;
type ControlStatus = (typeof controlStatus)[number];

@Component({ template: '', changeDetection: ChangeDetectionStrategy.OnPush })
export class BaseInputComponent<T>
  extends BaseComponent
  implements ControlValueAccessor, OnInit
{
  @Input() label?: string;
  @Input() placeholder = '';
  @Input() name!: string;
  @Input() id!: string;
  @Input() size: InputSize = 'base';

  // force the input valid state to be valid or invalid
  @Input() forceValidState?: ControlStatus;

  // giving the possibility to override the default error messages
  @Input() errorMessages: { [key: string]: string } = {};

  // It is used only for internal purposes
  public readonly formControl: FormControl = new FormControl<T | undefined>(
    undefined
  );

  get hasErrors() {
    if (!this.control?.control) {
      return undefined;
    }
    return this.control.control.errors ?? undefined;
  }

  private _isControlValid: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  public isControlValid$: Observable<boolean> =
    this._isControlValid.asObservable();

  get hasRequiredValidator() {
    return (
      this.control?.control &&
      this.control.control.hasValidator(Validators.required)
    );
  }

  private _control: NgControl | undefined = undefined;
  get control() {
    return this._control;
  }

  constructor(control: NgControl) {
    super();
    if (control !== undefined) {
      control.valueAccessor = this;
      this._control = control;
    }
  }

  onChange: (value: T) => void = noop;
  onTouch: () => void = noop;

  registerOnChange(fn: (value: T) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  writeValue(value: T): void {
    this.formControl.setValue(value);
  }

  ngOnInit(): void {
    this._initControlValidation();
    this.formControl.valueChanges
      .pipe(takeUntil(this._ngUnSubscribe))
      .subscribe((value: T) => {
        this.onChange(value);
      });
  }

  protected _initControlValidation() {
    if (this.forceValidState) {
      this._isControlValid.next(this.forceValidState === 'VALID');
    } else {
      this.control?.statusChanges
        ?.pipe(
          startWith(this.control?.status),
          pairwise(),
          takeUntil(this._ngUnSubscribe)
        )
        .subscribe(
          ([previousStatus, currentStatus]: [ControlStatus, ControlStatus]) => {
            if (this.control?.disabled) {
              this._isControlValid.next(true);
              return;
            }
            if (
              previousStatus === 'INVALID' &&
              currentStatus === 'INVALID' &&
              !this.control?.pristine
            ) {
              this._isControlValid.next(false);
              return;
            }
            if (currentStatus === 'VALID') {
              this._isControlValid.next(true);
            }

            // if(previousStatus)
            // const previousStatusBool = previousStatus === 'VALID';
            // const isPristine = this.control?.pristine ?? true;
            // const isValid = currentStatus === 'VALID' || isPristine;
            // if (previousStatusBool !== currentStatus) {
            //   this._isControlValid.next(isValid);
            // }
          }
        );
    }
  }
}
