import { NgModule } from '@angular/core';
import { FormWrapperComponent } from './form-wrapper.component';
import { FormWrapperTitleDirective } from './form-wrapper-title.directive';
import { CommonModule } from '@angular/common';
import { FormWrapperBlockComponent } from './form-wrapper-block.component';
import { FormWrapperActionsDirective } from './form-wrapper-actions.directive';
import { ButtonDirective } from '../../../directives';

@NgModule({
  imports: [CommonModule, FormWrapperTitleDirective, FormWrapperBlockComponent, FormWrapperActionsDirective, ButtonDirective],
  exports: [FormWrapperComponent, FormWrapperTitleDirective, FormWrapperBlockComponent, FormWrapperActionsDirective, ButtonDirective],
  declarations: [FormWrapperComponent],
})
export class FormWrapperModule { }
