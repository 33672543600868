import { HttpClientModule, HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { TranslateModule, TranslateLoader, TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";


export enum ApplicationLanguage {
  English = 'en',
  Italian = 'it',
  German = 'de',
}


class DyamicTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient) { }
  public getTranslation(lang: string): Observable<object> {
    return this.http.get('/assets/i18n/' + lang + '.json');
  }
}

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useClass: DyamicTranslateLoader,
        deps: [HttpClient]
      },
      isolate: true
    })
  ],
  providers: [
    TranslateService
  ],
})
export class LanguageModule {
  constructor(translateSvc: TranslateService) {
    if (Object.values(ApplicationLanguage).length === 0) {
      throw new Error('ApplicationLanguage enum is empty');
    } else {
      translateSvc.addLangs(Object.values(ApplicationLanguage));
    }
  }
}
