import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { ReactiveFormsModule, NgControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormControlComponent } from '../form-control/form-control.component';
import { InputDirective } from '../../../directives/input.directive';
import { FormErrorComponent } from '../form-error/form-error.component';
import { BaseInputComponent } from '../base-input.component';

export interface CheckboxChoice {
  id: string | number;
  label: string;
}

@Component({
  selector: 'ui-form-checkbox-group',
  standalone: true,
  templateUrl: './form-checkbox-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    FormControlComponent,
    InputDirective,
    FormErrorComponent,
  ],
})
export class FormCheckboxGroupComponent
  extends BaseInputComponent<Array<string | number>>
  implements OnInit
{
  @Input() inline = true;
  @Input() choices: CheckboxChoice[] = [];

  public selectedChoices: Array<string | number> = [];

  constructor(@Self() @Optional() control: NgControl) {
    super(control);
  }

  override writeValue(value: Array<string | number>): void {
    this.selectedChoices = value ?? [];
  }

  override ngOnInit(): void {
    this._initControlValidation();
  }

  onCheckboxChange(id: string | number) {
    if (this.selectedChoices.includes(id)) {
      this.selectedChoices = this.selectedChoices.filter((c) => c !== id);
    } else {
      this.selectedChoices.push(id);
    }
    this.onChange(this.selectedChoices);
  }
}
