import { Injectable } from '@angular/core';
import {
  FormGroup,
  FormArray,
  FormControl,
  AbstractControl,
} from '@angular/forms';

type NonNullableValues<T> = {
  [K in keyof T]: T[K] extends null ? never : T[K];
};

@Injectable({
  providedIn: 'root',
})
export class FormHelperService {
  // Validate if all controls in the form group are touched
  private _validateAllFormFields(formGroup: FormGroup | FormArray): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control) {
        if (control.errors) {
          console.log(field, control.errors);
        }
        if (control instanceof FormControl) {
          if (!control.disabled && control.errors) {
            control.markAsTouched();
            control.markAsDirty();
            control.setErrors(control.errors); // This line is needed to trigger the error messages and status changes in the form control
          }
        } else if (control instanceof FormGroup) {
          this._validateAllFormFields(control);
        } else if (control instanceof FormArray) {
          this._validateAllFormFields(control);
        }
      }
    });
  }

  // Validate if the form group is valid
  validateForm(formGroup: FormGroup): boolean {
    this._validateAllFormFields(formGroup);
    return formGroup.valid;
  }

  // Check if a form control has an error
  hasError(control: AbstractControl, errorName: string): boolean {
    return control.hasError(errorName) && control.touched;
  }

  removeNullValues<T>(formGroup: FormGroup): NonNullableValues<T> {
    const result: Partial<NonNullableValues<T>> = {};

    Object.keys(formGroup.getRawValue()).forEach((key) => {
      const control = formGroup.get(key);
      if (
        control !== null &&
        control.value !== null &&
        control.value !== undefined
      ) {
        result[key as keyof NonNullableValues<T>] = control.value;
      }
    });

    return result as NonNullableValues<T>;
  }

  areControlTouchedOrDirty(form: FormGroup): boolean {
    return (
      form.touched &&
      Object.values(form.controls).some(
        (control) => control.touched || control.dirty
      )
    );
  }
}

/**
 * export function getMappedValue<T>(value: T): T {
  return Object.keys(value).reduce((acc, curr) => {
    if (value[curr] !== null) {
      acc[curr] = value[curr];
    }
    return acc;
  }, {} as T);
}

//...

// Then we can use it in the component before sending the value to BE:
const valueToBeSent = getMappedValue(this.myForm.value);
 */
