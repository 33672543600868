import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

export type CustomErrorMessage = { [key: string]: string };

@Pipe({
  name: 'parseErrorMessage',
  standalone: true,
})
export class ParseErrorMessagePipe implements PipeTransform {
  constructor(private _translate: TranslateService) {}

  private _validationErrors: { [key: string]: string } = {
    required: this._translate.instant('Validation.message.required'),
    minlength: this._translate.instant('Validation.message.minLength'),
    maxlength: this._translate.instant('Validation.message.maxLength'),
    pattern: this._translate.instant('Validation.message.pattern'),
    email: this._translate.instant('Validation.message.email'),
    percentRange: this._translate.instant('Validation.message.percentRange'),
  };

  transform(
    validationFn: ValidationErrors | undefined,
    customMessages?: CustomErrorMessage
  ): string {
    if (!validationFn) {
      return '';
    }

    if (customMessages && Object.keys(customMessages).length > 0) {
      this._setCustomErrorMessages(customMessages);
    }

    for (const errorKey of Object.keys(this._validationErrors)) {
      if (validationFn[errorKey]) {
        const errorTemplate = this._validationErrors[errorKey];
        const errorParams = validationFn[errorKey];

        if (errorParams.requiredLength) {
          errorParams.requiredLength = Number(errorParams.requiredLength);
        }

        return this.interpolate(errorTemplate, errorParams);
      }
    }

    return this._translate.instant('Validation.message.invalidInput');
  }

  private _setCustomErrorMessages(customMessages: CustomErrorMessage): void {
    this._validationErrors = { ...this._validationErrors, ...customMessages };
  }

  private interpolate(
    template: string,
    params: { [key: string]: string }
  ): string {
    return template.replace(/\{\{\s*([\w]+)\s*\}\}/g, (match, param) => {
      return params[param].toString() || match;
    });
  }
}
