<button type="button" [class]="buttonClass" (click)="toggle()" #button>
  <ng-content select="[label]"></ng-content>
</button>
<div
  class="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 min-w-fit absolute"
  #dropdown
  [style.display]="isOpen ? 'block' : 'none'"
  [style.width]="width + 'px'"
>
  <ng-content select="[body]"></ng-content>
</div>
