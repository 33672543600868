import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormControlComponent } from '../form-control/form-control.component';
import { FormErrorComponent } from '../form-error/form-error.component';
import { BaseInputComponent } from '../base-input.component';
import { LocalizedDateFormatPipe } from '@budget-compass/frontend-core';
import { ButtonDirective, InputDirective } from '../../../directives';

@Component({
  selector: 'ui-form-month-picker',
  templateUrl: './form-month-picker.component.html',
  styles: [
    `
      input[type='month']::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
      }
    `,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    FormControlComponent,
    InputDirective,
    FormErrorComponent,
    TranslateModule,
    LocalizedDateFormatPipe,
    ButtonDirective,
  ],
})
export class FormMonthPickerComponent
  extends BaseInputComponent<string>
  implements OnInit
{
  @Input() hideNavigation = false;
  /**
   * Minimum date allowed
   * @format YYYY-MM
   */
  @Input() min: string;

  /**
   * Maximum date allowed
   * @format YYYY-MM
   */
  @Input() max: string;

  public get disableBackward(): boolean {
    if (!this.min) {
      return false;
    }

    const date = new Date(this.formControl.value);
    const min = new Date(this.min);
    return date <= min;
  }

  public get disableForward(): boolean {
    if (!this.max) {
      return false;
    }

    const date = new Date(this.formControl.value);
    const max = new Date(this.max);
    return date >= max;
  }

  constructor(@Self() @Optional() control: NgControl) {
    super(control);
  }

  override writeValue(value: string): void {
    if (value === null || value === undefined) {
      super.writeValue(value);
      return;
    }

    super.writeValue(value);
  }

  decreaseMonth(): void {
    if (this.disableBackward) {
      return;
    }
    const date = new Date(this.formControl.value);
    date.setMonth(date.getMonth() - 1);
    this.writeValue(date.toISOString().slice(0, 7));
  }

  increaseMonth(): void {
    if (this.disableForward) {
      return;
    }
    const date = new Date(this.formControl.value);
    date.setMonth(date.getMonth() + 1);
    this.writeValue(date.toISOString().slice(0, 7));
  }
}
