import { IBaseModel, Uuid } from '@framework/shared/core-util-types';
import { AppAccessRight } from './app-access-right';
import { Language } from '../enums';

export type IUserEntity = {
  email: Uuid;
  password: string;
  firstName: string;
  lastName: string;
  accessRights: AppAccessRight[];
  lastLogin?: Date;
  resetPassword: boolean;
  oneTimePassword: string | null;
  isArchived: boolean;
  language: Language;
  // project specific fields
  lastRecurringTransactionImport?: Date;
} & IBaseModel;

export type IUser = IUserEntity;

export type IUpdateUserParameter = Pick<
  IUserEntity,
  'firstName' | 'lastName' | 'email' | 'id'
>;
